<template>
<div class="footer_contacts">
  <ul class="footer_contacts__list">
    <li class="footer_contacts__item"
     v-for="contact, index in contacts" :key="index"
     v-html="contact.text">
    </li>
  </ul>
</div>
</template>

<script>
export default {
  data() {
    return {
      contacts: [{
        text: 'Телефон: <a href="tel:+74991367600">+7 499 136 76 00</a>',
      },
      {
        text: 'Электронная почта: <a href="mailto:class@class-cloud.ru">class@class-cloud.ru</a>',
      },
      {
        text: 'Адрес: 115162, Россия, Москва, ул. Шаболовка 31, стр. 11',
      },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.footer_contacts__item {
  line-height: 22px;
  padding-top: 15px;
}
</style>
