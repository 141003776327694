import { createRouter, createWebHistory } from 'vue-router';

const defaultLayoutMeta = {
  layout: 'default',
};

const routes = [
  {
    path: '/',
    name: 'class-cloud',
    meta: {
      layout: 'landing',
      title: 'CLASS.CLOUD',
      metaTags: [
        {
          name: 'description',
          content: 'CLASS.CLOUD',
        },
      ],
    },
    component: () => import('../views/ClassCloud.vue'),
  },
  {
    path: '/enter',
    name: 'enter',
    meta: {
      layout: 'landing',
      title: 'CLASS.CLOUD',
      metaTags: [
        {
          name: 'description',
          content: 'CLASS.CLOUD',
        },
      ],
    },
    component: () => import('../views/EnterPage.vue'),
  },
  {
    path: '/:pathMatch(.*)',
    name: 'not-found',
    meta: {
      ...defaultLayoutMeta,
      title: 'Not Found',
      metaTags: [
        {
          name: 'description',
          content: 'Not Found',
        },
      ],
    },
    component: () => import('../views/NotFound.vue'),
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return (savedPosition);
  }
  if (to.hash) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          el: to.hash,
          behavior: 'smooth',
          top: 110,
        });
      }, 900);
    });
  }
  return { left: 0, top: 0 };
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta?.title);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

  if (!nearestWithTitle) return next();
  if (nearestWithTitle?.meta?.metaTags?.length) {
    nearestWithTitle.meta.metaTags.map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        if (key === 'content' && tagDef[key] === 'Подробности новости') {
          tag.setAttribute(key, to.params.title);
        } else {
          tag.setAttribute(key, tagDef[key]);
        }
      });

      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
      .forEach((tag) => document.head.appendChild(tag));
  }

  // Удаление предыдущего канонического тега, если он существует
  const previousCanonical = document.querySelector('link[rel="canonical"]');
  if (previousCanonical) {
    document.head.removeChild(previousCanonical);
  }

  // Добавление нового канонического тега
  const canonicalLink = document.createElement('link');
  canonicalLink.setAttribute('rel', 'canonical');

  canonicalLink.setAttribute('href', 'https://class-cloud.online');
  document.head.appendChild(canonicalLink);
  return next();
});

export default router;
