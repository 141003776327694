<template>
<div class="footer__about">
  <div class="footer__about-text">
    Ctrl2GO является одним из крупнейших
    поставщиков решений для анализа данных в России.
  </div>

  <div class="logo footer__logo">
    <router-link :to="{name: 'home'}" class="logo__link">
      <div class="logo__img">
        <img class="logo__pic" src="/svg/footer-logo.svg" title="logo" alt="logo" />
      </div>
    </router-link>
  </div>
</div>
</template>

<style lang="scss" scoped>
.footer__about {
  @media (max-width: 1060px) {
    text-align: center;
  }

  @media (max-width: 1060px) {
    text-align: initial;
  }

  &-text {
    max-width: 210px;
    padding-bottom: 20px;

    @media (max-width: 1060px) {
      max-width: initial;
      padding-top: 20px;
    }
  }
}
</style>
